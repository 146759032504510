var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"national-global-comparison"},[(_vm.national_degree_loading)?[_c('DataLoading',{attrs:{"message":_vm.$t('institute.syllabi-comparison.comparison-loading-msg')}})]:[_c('GoBackHeader',{attrs:{"title":"Syllabi Comparison"}}),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"base-card"},[_c('p',{staticClass:"mb-8 base-section-sub-title"},[_vm._v("Your Degree")]),_c('div',{staticClass:"form-field"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","items":_vm.national_institute_data.institutes,"loading":_vm.get_institute_loading || _vm.national_degree_loading,"solo":"","disabled":_vm.national_degree_loading,"item-text":"institute_name","item-value":"id","clear-icon":"mdi-close","clearable":"","no-filter":"","placeholder":_vm.$t('institute.syllabi-comparison.select-national')},on:{"change":_vm.process_selected_institute},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-2 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-4 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}}]),model:{value:(_vm.national_institute_data.institute),callback:function ($$v) {_vm.$set(_vm.national_institute_data, "institute", $$v)},expression:"national_institute_data.institute"}})],1),_c('div',{staticClass:"form-field mt-8"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","items":_vm.national_institute_data.selected_institute_programs,"loading":_vm.get_institute_loading || _vm.national_degree_loading,"solo":"","disabled":!_vm.national_institute_data.institute || _vm.national_degree_loading,"item-text":"program_name","item-value":"id","clear-icon":"mdi-close","clearable":"","no-filter":"","placeholder":_vm.$t('institute.syllabi-comparison.select-program')},on:{"change":_vm.process_selected_national_program},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}}]),model:{value:(_vm.national_institute_data.program),callback:function ($$v) {_vm.$set(_vm.national_institute_data, "program", $$v)},expression:"national_institute_data.program"}})],1)])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"base-card"},[_c('p',{staticClass:"mb-8 base-section-sub-title"},[_vm._v("Compare With")]),_c('div',{staticClass:"form-field"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","items":_vm.global_institute_data.institutes,"loading":_vm.get_institute_loading || _vm.global_degree_loading,"solo":"","item-text":"institute_name","item-value":"id","clear-icon":"mdi-close","clearable":"","no-filter":"","placeholder":_vm.$t('institute.syllabi-comparison.select-global')},on:{"change":_vm.process_selected_global_institute},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-2 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.institute_logo,"alt":item.institute_name}}),_c('p',{staticClass:"mb-0 ms-4 base-body-text"},[_vm._v(" "+_vm._s(item.institute_name)+" ")])],1)]}}]),model:{value:(_vm.global_institute_data.institute),callback:function ($$v) {_vm.$set(_vm.global_institute_data, "institute", $$v)},expression:"global_institute_data.institute"}})],1),_c('div',{staticClass:"form-field mt-8"},[_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"return-object":"","hide-details":"","items":_vm.global_institute_data.selected_institute_programs,"loading":_vm.get_institute_loading || _vm.global_degree_loading,"solo":"","disabled":!_vm.global_institute_data.institute || _vm.global_degree_loading,"item-text":"program_name","item-value":"id","clear-icon":"mdi-close","clearable":"","no-filter":"","placeholder":_vm.$t('institute.syllabi-comparison.select-program')},on:{"change":_vm.process_selected_global_program},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(item.program_name)+" ")])]}}]),model:{value:(_vm.global_institute_data.program),callback:function ($$v) {_vm.$set(_vm.global_institute_data, "program", $$v)},expression:"global_institute_data.program"}})],1)])])],1),_c('v-row',{staticClass:"mt-8 actions-row"},[_c('v-col',[_c('div',{staticClass:"\n            d-flex\n            align-center\n            justify-space-between\n            flex-wrap\n            actions-row-wrapper\n          "},[_c('div',{staticClass:"skills-action d-flex align-center flex-wrap"},[_vm._l((_vm.all_actions),function(action,index){return [(action.action === _vm.ActionsTabs.GLOBAL_DEGREE_SKILLS)?_c('v-btn',{key:index,staticClass:"action-btn",class:{
                  'base-submit-button': _vm.active_tab === action.action,
                  'base-cancel-button': _vm.active_tab !== action.action
                },attrs:{"loading":_vm.global_degree_loading},on:{"click":function($event){return _vm.update_tab(action.action)}}},[_vm._v(" "+_vm._s(action.name)+" "),(
                    !_vm.global_degree_loading && _vm.global_institute_data.institute
                  )?_c('span',[_vm._v(" ("+_vm._s(_vm.global_institute_data.institute.abbreviation)+") ")]):_vm._e()]):(
                  action.action === _vm.ActionsTabs.NATIONAL_DEGREE_SKILLS
                )?_c('v-btn',{key:index,staticClass:"action-btn",class:{
                  'base-submit-button': _vm.active_tab === action.action,
                  'base-cancel-button': _vm.active_tab !== action.action
                },on:{"click":function($event){return _vm.update_tab(action.action)}}},[_vm._v(" "+_vm._s(action.name)+" "),(
                    !_vm.global_degree_loading && _vm.global_institute_data.institute
                  )?_c('span',[_vm._v(" ("+_vm._s(_vm.national_institute_data.institute.abbreviation)+") ")]):_vm._e()]):_c('v-btn',{key:index,staticClass:"action-btn",class:{
                  'base-submit-button': _vm.active_tab === action.action,
                  'base-cancel-button': _vm.active_tab !== action.action
                },attrs:{"loading":_vm.get_comparison_other_skills_loading,"disabled":!_vm.get_comparison_other_skills_loading &&
                  !_vm.get_comparison_other_skills},on:{"click":function($event){return _vm.update_tab(action.action)}}},[_vm._v(" "+_vm._s(action.name)+" ")])]}),_c('div',{staticClass:"d-flex align-center ms-xl-auto"},[_c('div',{staticClass:"\n                  base-card\n                  d-flex\n                  align-center\n                  py-5\n                  px-3\n                  mr-4\n                  score-card\n                "},[(
                    !_vm.get_comparison_other_skills_loading &&
                    _vm.get_comparison_other_skills
                  )?_c('v-progress-circular',{staticClass:"mr-3",attrs:{"color":"success","value":_vm.format_overall_score(
                      _vm.get_comparison_other_skills.info.matching_score
                    ),"size":"55","rotate":"270"}},[_vm._v(" "+_vm._s(_vm.format_overall_score( _vm.get_comparison_other_skills.info.matching_score ))+"% ")]):[_c('v-progress-circular',{staticClass:"mr-3",attrs:{"color":"success","indeterminate":"","size":"30"}})],_c('p',{staticClass:"mb-0 base-body-text font-weight-medium dark--text"},[_vm._v(" Match Score ")])],2),_c('v-btn',{staticClass:"base-submit-button upskill-btn",attrs:{"loading":_vm.global_degree_loading || _vm.get_comparison_other_skills_loading},on:{"click":_vm.upskill_courses}},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/linear/file-search.svg')}}),_vm._v(" Up Skill ")],1)],1)],2)])])],1),_c('v-row',[_c('v-col',[_c('SkillsWithGroupsV2',{attrs:{"skills":_vm.extracted_skills,"active_skill_group":_vm.active_skill_group},on:{"update_skill_group":_vm.update_skill_group}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }