















import Vue, { PropType } from "vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import SkillsChipsV2 from "@/components/shared/SkillsChipsV2.vue";

export default Vue.extend({
  name: "SkillsWithGroupsV2",
  components: { SkillsChipsV2, SkillsGroup },

  props: {
    active_skill_group: {
      type: String,
      required: true
    },
    skills: {
      type: Array as PropType<ExtractedSkillsWithGroup.Skills[]>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    update_skill_group(value: string) {
      this.$emit("update_skill_group", value);
    }
  }
});
